body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #faf8ef;
  color: #776e65;
  font-size: 18px;
  line-height: 28px;
}
h1 {
  font-size: 36px;
  line-height: 46px;
}
a {
  color: #776e65;
}
a:hover {
  text-decoration: none;
}
.home {
  display: flex;
  flex-direction: column;
  padding-top: 5%;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 16px;
  min-height: 600px;
  margin-top: 30px;
  padding-bottom: 5%;
}
.home .img-logo {
  width: 300px;
  height: auto;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
}
.home .img-city {
  position: absolute;
  background: url(../../assets/images/city.png);
  background-repeat: repeat-x;
  background-size: contain;
  height: 300px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  position: fixed;
  margin-top: 60px;
  width: 100%;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
}
.home .img-game {
  margin-top: 41px;
  margin-bottom: 41px;
  width: 300px;
  height: auto;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
}
.home .button-start {
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  border: none;
  font-size: 26px;
  font-weight: bold;
  border-radius: 12px;
  background-color: #F7AF31;
  height: 51px;
  color: white;
  width: 50%;
  margin-bottom: 16px;
}
.home .button-leader-board {
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  border: #F7AF31;
  font-size: 26px;
  font-weight: bold;
  border-radius: 12px;
  background-color: white;
  height: 51px;
  color: #F7AF31;
  width: 50%;
  margin-bottom: 16px;
}
.home .text-license {
  font-size: 14px;
  color: white;
  opacity: 0.4;
  text-align: center;
  margin-top: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
}
@media screen and (max-width: 768px) {
  .home {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .home .img-logo {
    width: 200px;
  }
  .home .img-game {
    width: 200px;
  }
  .home .img-city {
    background-position: bottom;
  }
  .home .button-leader-board {
    width: 300px;
    font-size: 18px;
  }
  .home .button-start {
    width: 300px;
    font-size: 18px;
  }
  .home .text-license {
    font-size: 11px;
  }
}
