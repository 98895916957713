body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #faf8ef;
  color: #776e65;
  font-size: 18px;
  line-height: 28px;
}
h1 {
  font-size: 36px;
  line-height: 46px;
}
a {
  color: #776e65;
}
a:hover {
  text-decoration: none;
}
.modal-control {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 100%;
  touch-action: none;
  top: 0;
  left: 0;
}
.Modal {
  position: absolute;
  left: 40px;
  height: 0px;
  z-index: 90999;
  min-height: 250px;
  padding-bottom: 40px;
  display: flex;
  width: 400px;
  right: 40px;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 16px;
  background-color: black !important;
}
.Modal .score-control {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.Modal .score-control .text-score-header {
  font-size: 30px;
  color: white;
  text-align: center;
}
.Modal .score-control .text-score {
  font-size: 70px;
  margin-top: 30px;
  margin-bottom: 30px;
  color: #F7AF31;
  font-weight: bold;
  text-align: center;
}
.Modal .score-control .close-text {
  cursor: pointer;
  color: white;
  margin-top: auto;
  font-size: 16px;
  text-decoration: underline;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .Modal {
    left: 0;
    right: 0;
    width: 80%;
  }
}
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
