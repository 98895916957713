body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #faf8ef;
  color: #776e65;
  font-size: 18px;
  line-height: 28px;
}
h1 {
  font-size: 36px;
  line-height: 46px;
}
a {
  color: #776e65;
}
a:hover {
  text-decoration: none;
}
.play {
  padding-top: 5%;
}
.play .img-logo {
  width: 300px;
  height: auto;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
}
.play .img-city-2 {
  position: absolute;
  background: url(../../assets/images/city.png);
  background-repeat: repeat-x;
  background-size: contain;
  height: 300px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  position: fixed;
  margin-top: 60px;
  width: 100%;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 768px) {
  .play {
    width: 100%;
  }
  .play .img-logo {
    width: 200px;
  }
  .play .img-game {
    width: 200px;
  }
  .play .img-city-2 {
    position: absolute;
    background: url(../../assets/images/city.png);
    background-repeat: repeat-x;
    background-size: contain;
    height: 300px;
    left: 0;
    right: 0;
    bottom: auto;
    z-index: -1;
    margin-top: 60px;
    width: 100%;
    object-fit: contain;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 768px) {
  body {
    overscroll-behavior: contain !important;
    overflow: hidden !important;
  }
}
