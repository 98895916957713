body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #faf8ef;
  color: #776e65;
  font-size: 18px;
  line-height: 28px;
}
h1 {
  font-size: 36px;
  line-height: 46px;
}
a {
  color: #776e65;
}
a:hover {
  text-decoration: none;
}
.grid {
  display: flex;
  flex-wrap: wrap;
  background-color: #bbada0;
  border: 8px solid #bbada0;
  border-radius: 6px;
}
.grid-cell {
  width: 100px;
  height: 100px;
  margin: 8px;
  border-radius: 4px;
  background: rgba(238, 228, 218, 0.35);
}
@media screen and (max-width: 576px) {
  .grid-cell {
    width: 72px;
    height: 72px;
  }
}
@media screen and (max-width: 410px) {
  .grid-cell {
    width: 64px;
    height: 64px;
  }
}
@media screen and (max-width: 375px) {
  .grid-cell {
    width: 56px;
    height: 56px;
  }
}
@media screen and (max-width: 576px) {
  .grid {
    width: 352px !important;
  }
}
@media screen and (max-width: 410px) {
  .grid {
    width: 325px !important;
  }
}
@media screen and (max-width: 376px) {
  .grid {
    width: 325px !important;
  }
}
