body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #faf8ef;
  color: #776e65;
  font-size: 18px;
  line-height: 28px;
}
h1 {
  font-size: 36px;
  line-height: 46px;
}
a {
  color: #776e65;
}
a:hover {
  text-decoration: none;
}
.tile {
  position: absolute;
  width: 100px;
  height: 100px;
  margin: 8px;
  border-radius: 4px;
  background-color: #eee4da;
  color: #776e65;
  font-weight: bold;
  display: flex;
  text-align: center;
  font-size: 48px;
  line-height: 2.1;
  transition-property: left, top, transform;
  transition-duration: 250ms, 250ms, 100ms;
  transform: scale(1);
  /* -=-=-=- START OF TEXT SIZE -=-=-=- */
  /* -=-=-=-= END OF TEXT SIZE =-=-=-=- */
}
.tile-128,
.tile-256,
.tile-512 {
  font-size: 44px;
  line-height: 2.28;
}
.tile-1024,
.tile-2048 {
  font-size: 32px;
  line-height: 3.18;
}
.tile-2 {
  position: absolute;
  z-index: 1;
  font-size: 70px;
  box-shadow: 0 0 30px 10px rgba(243, 215, 116, 0), inset 0 0 0 1px rgba(255, 255, 255, 0);
}
.tile .tile-control {
  display: flex;
  flex-direction: column;
  padding: 8px;
  justify-content: center;
  margin: auto;
}
.tile .font-bg {
  z-index: 1;
  font-size: 16px;
  line-height: 23px;
  background-color: transparent;
  box-shadow: 0 0 30px 10px transparent,
      inset 0 0 0 1px transparent;
  color: white;
  opacity: 0.9;
  text-shadow: 0 0 5px black;
}
.tile-4 {
  background: #ede0c8;
  box-shadow: 0 0 30px 10px rgba(243, 215, 116, 0), inset 0 0 0 1px rgba(255, 255, 255, 0);
}
.tile-8 {
  color: #f9f6f2;
  background: #f2b179;
}
.tile-16 {
  color: #f9f6f2;
  background: #f59563;
}
.tile-32 {
  color: #f9f6f2;
  background: #f67c5f;
}
.tile-64 {
  color: #f9f6f2;
  background: #f65e3b;
}
.tile-128 {
  color: #f9f6f2;
  background: #edcf72;
  box-shadow: 0 0 30px 10px rgba(243, 215, 116, 0.2381), inset 0 0 0 1px rgba(255, 255, 255, 0.14286);
}
.tile-256 {
  color: #f9f6f2;
  background: #edcc61;
  box-shadow: 0 0 30px 10px rgba(243, 215, 116, 0.31746), inset 0 0 0 1px rgba(255, 255, 255, 0.19048);
}
.tile-512 {
  color: #f9f6f2;
  background: #edc850;
  box-shadow: 0 0 30px 10px rgba(243, 215, 116, 0.39683), inset 0 0 0 1px rgba(255, 255, 255, 0.2381);
}
.tile-1024 {
  color: #f9f6f2;
  background: #edc53f;
  box-shadow: 0 0 30px 10px rgba(243, 215, 116, 0.47619), inset 0 0 0 1px rgba(255, 255, 255, 0.28571);
}
.tile-2048 {
  color: #f9f6f2;
  background: #edc22e;
  box-shadow: 0 0 30px 10px rgba(243, 215, 116, 0.55556), inset 0 0 0 1px rgba(255, 255, 255, 0.33333);
}
@media screen and (max-width: 576px) {
  .tile .font-bg {
    font-size: 13px;
    line-height: 14px;
  }
  .tile .tile-control {
    padding: 2px;
    margin-top: auto;
    margin-bottom: auto;
  }
}
.pos-img {
  width: 70px;
  height: 70px;
  object-fit: contain;
}
@media screen and (max-width: 576px) {
  .pos-img {
    width: 35px;
    height: 35px;
    object-fit: contain;
    margin: auto;
  }
}
@media screen and (max-width: 576px) {
  .tile {
    width: 72px;
    height: 72px;
  }
}
@media screen and (max-width: 410px) {
  .tile {
    width: 64px;
    height: 64px;
  }
}
@media screen and (max-width: 375px) {
  .tile {
    width: 56px;
    height: 56px;
  }
}
