body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #faf8ef;
  color: #776e65;
  font-size: 18px;
  line-height: 28px;
}
h1 {
  font-size: 36px;
  line-height: 46px;
}
a {
  color: #776e65;
}
a:hover {
  text-decoration: none;
}
.container-control {
  padding-left: 5%;
  padding-right: 5%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.mobile-custom {
  display: none;
}
.mobile-control {
  width: 100%;
}
.input-control {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.input-style {
  width: 80%;
  height: 51px;
  border-radius: 14px;
  margin-left: auto;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  margin-right: auto;
}
.button-start {
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  border: none;
  font-size: 14px;
  margin-top: 16px;
  font-weight: bold;
  border-radius: 12px;
  background-color: #F7AF31;
  height: 41px;
  color: white;
  width: 50%;
}
.desktop-custom {
  display: flex;
}
@media screen and (max-width: 768px) {
  .mobile-custom {
    display: block;
    width: 90%;
  }
  .mobile-control {
    width: 100%;
  }
  .desktop-custom {
    display: none;
  }
  .container-control {
    padding-left: 0%;
    padding-right: 0%;
    display: block;
  }
}
