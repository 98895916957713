body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #faf8ef;
  color: #776e65;
  font-size: 18px;
  line-height: 28px;
}
h1 {
  font-size: 36px;
  line-height: 46px;
}
a {
  color: #776e65;
}
a:hover {
  text-decoration: none;
}
.board {
  position: relative;
}
.tile-container {
  position: absolute;
  z-index: 2;
  margin: 8px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
@media screen and (max-width: 576px) {
  .tile-container {
    position: absolute;
    z-index: 2;
    margin: 8px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .board {
    width: 100% !important;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 375px) {
  .tile-container {
    position: absolute;
    z-index: 2;
    margin: 8px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .board {
    width: 100% !important;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 346px) {
  .tile-container {
    position: absolute;
    z-index: 2;
    margin: 8px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .board {
    width: 100% !important;
    margin-left: auto;
    margin-right: auto;
  }
}
