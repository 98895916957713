body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #faf8ef;
  color: #776e65;
  font-size: 18px;
  line-height: 28px;
}
h1 {
  font-size: 36px;
  line-height: 46px;
}
a {
  color: #776e65;
}
a:hover {
  text-decoration: none;
}
.lb-control {
  display: flex;
  flex-direction: column;
  padding-top: 5%;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 16px;
  min-height: 600px;
  margin-top: 30px;
  padding-bottom: 5%;
}
.lb-control .img-logo {
  width: 150px;
  height: auto;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
}
.lb-control .text-header-leader {
  font-size: 40px;
  color: white;
  font-weight: bold;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
}
.lb-control .img-city {
  position: absolute;
  background: url(../../assets/images/city.png);
  background-repeat: repeat-x;
  background-size: contain;
  height: 300px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  position: fixed;
  margin-top: 60px;
  width: 100%;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
}
.lb-control .button-home {
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  border: none;
  font-size: 26px;
  font-weight: bold;
  border-radius: 12px;
  background-color: #F7AF31;
  height: 51px;
  color: white;
  width: 50%;
  margin-top: 24px;
  margin-bottom: 16px;
}
.lb-control .grid-control {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.lb-control .grid-leader {
  display: grid;
  grid-template-columns: 20% 40% 40%;
  margin-left: auto;
  width: 80%;
  margin-right: auto;
}
.lb-control .grid-item {
  border: 2px white solid;
  padding-top: 8px;
  padding-bottom: 8px;
}
.lb-control .leader-board-text {
  color: white;
  text-align: center;
  font-size: 18px;
}
@media screen and (max-width: 768px) {
  .lb-control .leader-board-text {
    font-size: 15px;
  }
  .lb-control .text-header-leader {
    font-size: 24px;
  }
  .lb-control .button-home {
    font-size: 20px;
  }
  .lb-control .grid-item {
    border: 1px white solid;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .lb-control .img-city {
    background-position: bottom;
  }
}
