body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #faf8ef;
  color: #776e65;
  font-size: 18px;
  line-height: 28px;
}
h1 {
  font-size: 36px;
  line-height: 46px;
}
a {
  color: #776e65;
}
a:hover {
  text-decoration: none;
}
.App {
  max-width: 720px;
  margin: 0px auto;
}
.App .header {
  display: flex;
  width: 100%;
}
.App .header > div {
  flex: 1;
}
.App .header .button {
  float: right;
  margin-left: auto !important;
  margin-right: auto !important;
  margin: 26px 0px;
}
@media screen and (max-width: 500px) {
  .App .header .button {
    float: none;
    margin: 26px 0px;
  }
}
.App .footer {
  margin: 24px 0px;
  font-weight: bold;
  text-align: center;
}
.App .button-cursor {
  cursor: pointer;
}
.App .grid-control {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.App .grid-control .button-control {
  margin-top: 24px;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  background-color: #776e65;
  border: none;
  border-radius: 16px;
  height: 51px;
  color: white;
  font-size: 20px;
}
.App .game-control {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  margin-top: 40px;
}
@media screen and (max-width: 576) {
  .App .grid-control {
    display: block;
    grid-template-columns: none;
    justify-content: center;
    text-align: center;
  }
  .App .game-control {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    margin-top: 40px;
  }
  .App .button-cursor {
    text-align: center;
    display: block;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 576px) {
  .App {
    overscroll-behavior-y: contain;
  }
}
